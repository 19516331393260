// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback_footer__PexIC {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.feedback_text__B6oxU {
  margin: 0;
  font-size: 14px;
  color: #bfc8d0;
}

.feedback_links__H6z7D {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.feedback_link__\\+GtMW {
  color: #bfc8d0;
  text-decoration: none;
  line-height: 150%;
}

.feedback_link__\\+GtMW:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/feedback/feedback.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,SAAS;EACT,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".footer {\r\n  text-align: center;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  gap: 5px;\r\n}\r\n\r\n.text {\r\n  margin: 0;\r\n  font-size: 14px;\r\n  color: #bfc8d0;\r\n}\r\n\r\n.links {\r\n  text-align: center;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  flex-direction: row;\r\n  gap: 20px;\r\n}\r\n.link {\r\n  color: #bfc8d0;\r\n  text-decoration: none;\r\n  line-height: 150%;\r\n}\r\n\r\n.link:hover {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `feedback_footer__PexIC`,
	"text": `feedback_text__B6oxU`,
	"links": `feedback_links__H6z7D`,
	"link": `feedback_link__+GtMW`
};
export default ___CSS_LOADER_EXPORT___;
