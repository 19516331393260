// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_headerWrapper__AEqXI {
  position: sticky;
  top: 0;
  z-index: 10;
}
.header_header__z8ezc {
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  background: var(--header-background);
  border: 1px solid var(--color-border);
  border-radius: 25px;
  padding-inline: 20px;
  padding-top: 100px;
  padding-bottom: 30px;
  gap: 15px;
  height: 347px;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.2s;
  margin: 5px;
  box-sizing: border-box;
}
.header_title__j5wSX {
  font-size: 32px;
  font-weight: 300;
  transition: all 0.2s;
  color: var(--text-color);
}
.header_title__j5wSX p {
  margin: 0;
}
.header_text__0xFdP {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,oCAAoC;EACpC,qCAAqC;EACrC,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,SAAS;EACT,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".headerWrapper {\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 10;\r\n}\r\n.header {\r\n  position: relative;\r\n  display: flex;\r\n  max-width: 100%;\r\n  flex-direction: column;\r\n  background: var(--header-background);\r\n  border: 1px solid var(--color-border);\r\n  border-radius: 25px;\r\n  padding-inline: 20px;\r\n  padding-top: 100px;\r\n  padding-bottom: 30px;\r\n  gap: 15px;\r\n  height: 347px;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n  transition: all 0.2s;\r\n  margin: 5px;\r\n  box-sizing: border-box;\r\n}\r\n.title {\r\n  font-size: 32px;\r\n  font-weight: 300;\r\n  transition: all 0.2s;\r\n  color: var(--text-color);\r\n}\r\n.title p {\r\n  margin: 0;\r\n}\r\n.text {\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `header_headerWrapper__AEqXI`,
	"header": `header_header__z8ezc`,
	"title": `header_title__j5wSX`,
	"text": `header_text__0xFdP`
};
export default ___CSS_LOADER_EXPORT___;
