// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme_text__7SRtf{
    display: flex;
    align-items: center;
    gap: 15px;
}

.theme_checkbox__lmA5q {
    position: relative;
      border: 2px solid var(--text-color-d);
      border-radius: 2px;
      background: var(--text-color-d);
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 0 !important;
      vertical-align: text-top;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
    opacity: .5;
}
  
.theme_checkbox__lmA5q:hover {
    opacity: 1;
  }
  
.theme_checkbox__lmA5q:checked {
    background-color: var(--text-color-d);
    opacity: 1;
}
  
 .theme_checkbox__lmA5q:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border:2px solid var(--text-color-d);
    border-top: 0;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

  .theme_checkbox__lmA5q:checked:before {
    border-color: #2D3339;
  }`, "",{"version":3,"sources":["webpack://./src/components/theme/theme.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;MAChB,qCAAqC;MACrC,kBAAkB;MAClB,+BAA+B;MAC/B,eAAe;MACf,cAAc;MACd,kBAAkB;MAClB,UAAU;MACV,qBAAqB;MACrB,wBAAwB;MACxB,YAAY;MACZ,WAAW;MACX,wBAAwB;IAC1B,WAAW;AACf;;AAEA;IACI,UAAU;EACZ;;AAEF;IACI,qCAAqC;IACrC,UAAU;AACd;;CAEC;IACG,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,yBAAyB;IACzB,wBAAwB;IACxB,8CAA8C;IAC9C,UAAU;EACZ;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".text{\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 15px;\r\n}\r\n\r\n.checkbox {\r\n    position: relative;\r\n      border: 2px solid var(--text-color-d);\r\n      border-radius: 2px;\r\n      background: var(--text-color-d);\r\n      cursor: pointer;\r\n      line-height: 0;\r\n      margin: 0 .6em 0 0;\r\n      outline: 0;\r\n      padding: 0 !important;\r\n      vertical-align: text-top;\r\n      height: 20px;\r\n      width: 20px;\r\n      -webkit-appearance: none;\r\n    opacity: .5;\r\n}\r\n  \r\n.checkbox:hover {\r\n    opacity: 1;\r\n  }\r\n  \r\n.checkbox:checked {\r\n    background-color: var(--text-color-d);\r\n    opacity: 1;\r\n}\r\n  \r\n .checkbox:before {\r\n    content: '';\r\n    position: absolute;\r\n    right: 50%;\r\n    top: 50%;\r\n    width: 4px;\r\n    height: 10px;\r\n    border:2px solid var(--text-color-d);\r\n    border-top: 0;\r\n    border-width: 0 2px 2px 0;\r\n    margin: -1px -1px 0 -1px;\r\n    transform: rotate(45deg) translate(-50%, -50%);\r\n    z-index: 2;\r\n  }\r\n\r\n  .checkbox:checked:before {\r\n    border-color: #2D3339;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `theme_text__7SRtf`,
	"checkbox": `theme_checkbox__lmA5q`
};
export default ___CSS_LOADER_EXPORT___;
